import React from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Link from "@material-ui/core/Link";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import FacebookIcon from "@material-ui/icons/Facebook";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  rightBox: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

export default function () {
  const classes = useStyles();

  return (
    <Box bgcolor="primary.dark" color="primary.contrastText">
      <Container>
        <Box py={3} display="flex" alignItems="center">
          <Box flexGrow={1} display="flex">
            <Typography variant="body2">© {new Date().getFullYear()} LTC Business, Inc. All rights reserved. <Link href="/liability-disclaimer" color="inherit">Liability Disclaimer</Link>.</Typography>
          </Box>
          <IconButton
            aria-label="facebook"
            href="https://facebook.com/fabriccarewashateria"
            style={{
              color: "#fff",
            }}
            disableRipple
          >
            <FacebookIcon fontSize="large" />
          </IconButton>
        </Box>
      </Container>
    </Box>
  );
}
