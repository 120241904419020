import React from 'react';

export default () => {
  return (
    <div style={{
      marginLeft: '1rem',
      marginRight: '1rem',
    }}>
      <h3>FABRIC CARE WASHATERIA LIABILITY DISCLAIMER</h3>
      <p>Dear valued customer,</p>
      <p>Welcome to Fabric Care Washateria! We are delighted to provide you with our self-service laundromat
facilities. Before you proceed to use our services, we kindly request that you carefully read and
understand the following liability disclaimer.</p>
      <ol>
          <li>
              <strong>Use of Facilities:</strong> Fabric Care Washateria maintains clean, well-maintained, and safe facilities for your
              laundry needs. However, you agree that your use of the laundromat is at your own risk. We recommend
              using the equipment and facilities with caution and in accordance with the provided guidelines and
              instructions.
          </li>
          <li>
              <strong>Personal Belongings:</strong> While we strive to maintain a secure environment, Fabric Care Washateria is not
              liable for any loss, theft, or damage to personal belongings left unattended on the premises. Please
              ensure that you keep a close eye on your possessions at all times.
          </li>
          <li>
              <strong>Equipment Operation:</strong> Our laundromat provides various washing and drying machines for your
              convenience. It is essential to use the machines responsibly and correctly. Fabric Care Washateria is not
              liable for any damage to clothing or personal items caused by improper use of the equipment or failure
              to follow instructions posted on the machines.
          </li>
          <li>
              <strong>Injury or Accidents:</strong> Your safety is our priority, and we make efforts to maintain a safe environment.
              However, Fabric Care Washateria is not responsible for any injuries or accidents that may occur on the
              premises due to the actions of customers or any other unforeseen circumstances.
          </li>
          <li>
              <strong>Children and Pets:</strong> Children and pets are welcome here, but they must be supervised by a responsible
              adult at all times. Fabric Care Washateria is not liable for any injuries or accidents involving children or
              pets.
          </li>
          <li>
              <strong>Cleaning Products and Supplies:</strong> Fabric Care Washateria may provide cleaning products and supplies
              for your use. It is your responsibility to read and follow the instructions on these products carefully.
              Fabric Care Washateria is not liable for any adverse reactions or damages resulting from the use of
              provided cleaning products.
          </li>
          <li>
              <strong>Service Interruptions:</strong> While we strive to provide continuous and uninterrupted service, Fabric Care
              Washateria may experience occasional maintenance or technical issues that could result in service
              interruptions. We apologize for any inconvenience caused but cannot be held responsible for any
              resulting damages or losses.
          </li>
          <li>
              <strong>Damage to Clothing:</strong> Although we take great care to provide high-quality equipment and maintain
              them regularly, Fabric Care Washateria is not responsible for any damage that may occur to clothing or
              personal items during the laundry process.
          </li>
      </ol>
      <p>By using the facilities and services provided by Fabric Care Washateria, you acknowledge and agree to
      the terms and conditions outlined in this liability disclaimer. If you do not agree with any part of this
      disclaimer, we kindly ask you to refrain from using our laundromat services.</p>
      <p>Thank you for choosing Fabric Care Washateria. We appreciate your understanding and cooperation.</p>
      <p>
        Sincerely,<br />
        Fabric Care Washateria<br />
        2928 Government St.<br />
        (225) 383-8729<br />
      </p>
    </div>
  )
}
