import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  contentContainer: {
    [theme.breakpoints.up("md")]: {
      maxWidth: "50rem",
    },
  },
  h4: {
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.9rem",
      lineHeight: "2rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "2.5rem",
    },
  },
  h6: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem",
      lineHeight: "1.8rem",
      padding: theme.spacing(0, 2),
    },
  },
}));

export default function () {
  const classes = useStyles();

  // https://dev.to/paramharrison/background-image-with-gradient-overlay-in-css-4gcb
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      textAlign="center"
      pt={10}
      pb={10}
      style={
        {
          // backgroundColor: "#f9f8f9",
        }
      }
    >
      <Box className={classes.contentContainer}>
        <Typography className={classes.h4} variant="h4" color="primary">
          Or let us do the wash.
        </Typography>
        <Box pt={3}>
          <Typography
            variant="h6"
            className={classes.h6}
            style={{
              paddingBottom: "1rem",
            }}
          >
            Drop off and pick up your clothes at our drive-through window.
          </Typography>
          <Typography variant="h6" className={classes.h6}>
            We'll wash, dry and fold them according to your needs.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
