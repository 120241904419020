import React from "react";
import Container from "@material-ui/core/Container";
import Paper from "@material-ui/core/Paper";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  // outerContainer: {
  //   backgroundColor: theme.palette.primary.gray,
  // },
  root: {
    marginTop: theme.spacing(-8),
    marginBottom: theme.spacing(-8),
    position: "relative",
  },
  contentContainer: {
    [theme.breakpoints.down("sm")]: {
      // textAlign: "left",
      padding: theme.spacing(0, 2),
      // "& h5": {
      //   paddingBottom: theme.spacing(2),
      // },
    },
    [theme.breakpoints.up("md")]: {
      maxWidth: "50rem",
    },
  },
  h4: {
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.9rem",
      lineHeight: "2rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "2.5rem",
    },
  },
  h5: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem",
      lineHeight: "2.2rem",
    },
    [theme.breakpoints.up("md")]: {
      lineHeight: "2.5rem",
    },
  },
}));

export default function ({ title, children }) {
  const classes = useStyles();

  // https://dev.to/paramharrison/background-image-with-gradient-overlay-in-css-4gcb
  return (
    <Container className={classes.root}>
      <Paper elevation={3}>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          textAlign="center"
          py={10}
          bgcolor="primary.main"
        >
          <Box className={classes.contentContainer} style={{ color: "#fff" }}>
            <Typography className={classes.h4} variant="h4">
              What We Offer
            </Typography>
            <Box pt={3}>
              <Typography className={classes.h5} variant="h5">
                Self-service laundry
              </Typography>
              <Typography className={classes.h5} variant="h5">
                Drop off wash, dry and fold
              </Typography>
              <Typography className={classes.h5} variant="h5">
                Commercial accounts
              </Typography>
              <Typography className={classes.h5} variant="h5">
                Comforter cleaning
              </Typography>
              <Typography className={classes.h5} variant="h5">
                Table cloth cleaning
              </Typography>
              <Typography className={classes.h5} variant="h5">
                Sleeping bag cleaning
              </Typography>
              <Typography className={classes.h5} variant="h5">
                State of the art equipment
              </Typography>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
}
