// https://favicon.io/favicon-generator/
import React from "react";
import Header from "components/Header/Header";
import Hero from "components/Hero/Hero";
import Teaser from "components/Teaser/Teaser";
import TextMedia from "components/TextMedia/TextMedia";
import TextMap from "components/TextMap/TextMap";
import Pop from "components/Pop/Pop";
import Footer from "components/Footer/Footer";
// import Alert from "@material-ui/lab/Alert";
// import Box from "@material-ui/core/Box";
// import Typography from "@material-ui/core/Typography";
import { ThemeProvider } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";

import theme from "themes/fabriccare";
const useStyles = makeStyles((theme) => ({
  map: {
    [theme.breakpoints.up("sm")]: {
      // padding: `0 ${theme.spacing(3)}px`,
    },
  },
  alertRoot: {
    justifyContent: "center",
  },
}));

function App() {
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <main>
        <Header />
        {/* <Alert
          severity="info"
          classes={{
            root: classes.alertRoot,
          }}
        >
          We're now open during regular store hours.
        </Alert> */}
        <Hero />
        <Teaser />
        <TextMedia />
        <Pop />
        <TextMap />
        <Footer />
      </main>
    </ThemeProvider>
  );
}

export default App;
