import React from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  h4: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem",
      lineHeight: "1.4rem",
    },
  },
  h5: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem",
      lineHeight: "1.8rem",
    },
  },
}));

export default function () {
  const classes = useStyles();

  // https://dev.to/paramharrison/background-image-with-gradient-overlay-in-css-4gcb
  // "linear-gradient(to bottom right, rgba(0, 47, 75, 0.8), rgba(220, 66, 37, 0.8)), url(/images/washers.jpg)",
  // https://coolors.co/1d42e4
  return (
    <>
      <Box
        style={{
          background:
            "linear-gradient(to bottom right, rgba(29, 66, 228, 0.75), rgba(29, 66, 228, 0.75)), url(/images/washers.jpg)",
          backgroundSize: "cover",
          backgroundPosition: "center",
          height: "30rem",
        }}
        display="flex"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
      >
        <Box>
          <Box pb={2}>
            <Typography
              variant="h1"
              style={{
                fontSize: "4rem",
                lineHeight: "4.4rem",
                fontWeight: "bold",
                color: "#fff",
              }}
            >
              Ultra Spin Washers
            </Typography>
          </Box>
          <Typography
            className={classes.h4}
            variant="h4"
            style={{
              color: "#fff",
            }}
          >
            {/*Reduces time to dry your clothes by up to 80%.*/}
            Trims drying time by as much as 25%
          </Typography>
        </Box>
      </Box>
      <Box py={5} bgcolor="primary.main" textAlign="center">
        <Container>
          <Typography
            variant="h5"
            className={classes.h5}
            style={{
              color: "#fff",
            }}
          >
            Come wash your clothes with the <strong>best equipment</strong> in
            Baton Rouge.
          </Typography>
        </Container>
      </Box>
    </>
  );
}
