import React from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      "& p": {
        paddingBottom: theme.spacing(1),
      },
    },
  },
  // gridContainer: {
  //   [theme.breakpoints.down("sm")]: {
  //     flexDirection: "column-reverse",
  //   },
  // },
  textContainer: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
      // paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(4),
    },
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(16, 0),
      paddingRight: theme.spacing(2),
    },
  },
  map: {
    width: "50%",
    [theme.breakpoints.up("sm")]: {
      // padding: `0 ${theme.spacing(3)}px`,
    },
  },
  h4: {
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.9rem",
      lineHeight: "2rem",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: "2.5rem",
    },
  },
  h5: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "1.2rem",
      lineHeight: "2.2rem",
    },
    [theme.breakpoints.up("md")]: {
      lineHeight: "2.5rem",
    },
  },
}));

export default function () {
  const classes = useStyles();

  // https://dev.to/paramharrison/background-image-with-gradient-overlay-in-css-4gcb
  return (
    <Box
      className={classes.root}
      style={{
        backgroundColor: "#f9f8f9",
      }}
      pt={10}
      pb={18}
    >
      <Container>
        <Box
          display="flex"
          style={
            {
              // backgroundColor: "#fff",
            }
          }
        >
          <Grid
            container
            spacing={0}
            // classes={{
            //   container: classes.gridContainer,
            // }}
          >
            <Grid item xs={12} md={6}>
              <Box flexGrow={1} className={classes.textContainer}>
                <Box>
                  <Typography
                    className={classes.h4}
                    variant="h4"
                    style={{
                      fontWeight: "bold",
                    }}
                    color="primary"
                  >
                    State of The Art
                  </Typography>
                </Box>
                <Box pt={3}>
                  <Typography className={classes.h5} variant="h5">
                    80lb, 60lb, 35lb and 18lb Washers
                  </Typography>
                  <Typography className={classes.h5} variant="h5">
                    10lb Top Load Washers
                  </Typography>
                  <Typography className={classes.h5} variant="h5">
                    13 Ultra High Spin Speed Washers
                  </Typography>
                  <Typography className={classes.h5} variant="h5">
                    40+ Computerized Dryers
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              style={{
                minHeight: "16rem",
              }}
            >
              <Box
                style={{
                  background: "url(/images/dryers_zoomed.jpg)",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  height: "100%",
                }}
              ></Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}
