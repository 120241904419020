import React from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  logo: {
    [theme.breakpoints.down("sm")]: {
      textAlign: "center",
    },
  },
  rightBox: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

export default function () {
  const classes = useStyles();

  return (
    <Container>
      <Box py={3} display="flex" alignItems="center">
        <Box flexGrow={1} className={classes.logo}>
          <Typography
            color="primary"
            style={{
              fontSize: "1.4rem",
              fontWeight: "bold",
              lineHeight: "1.8rem",
            }}
          >
            FABRIC CARE WASHATERIA
          </Typography>
        </Box>
        <Box className={classes.rightBox}>
          {/*<Box pb={1}>
            <strong>Store Hours</strong>
          </Box>
          Mon-Fri: 7am-10:30pm
          <br />
          Sat-Sun: 6:30am-10:30pm*/}
          Self-service laundromat with drive-through service
        </Box>
      </Box>
    </Container>
  );
}
