import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";

const palette = {
  primary: {
    main: "#1d42e4",
    // light: "#CED6F9",
    // dark: "#0D2072",
    contrastText: "#FFFFFF",
    gray: "#f9f8f9",
  },
  // primary: { main: "#B71C1C" },
  secondary: { main: "#A5D8D0", dark: "#6AC8BA", contrastText: "#FFFFFF" },
  // secondary: { main: "#00695C" }
};

const typography = {
  fontFamily: [`Montserrat`, `sans-serif`].join(`,`),
};

export default responsiveFontSizes(
  createMuiTheme({
    palette,
    typography,
  })
);
