import React from "react";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.down("sm")]: {
      "& p": {
        paddingBottom: theme.spacing(2),
      },
    },
  },
  textContainer: {
    [theme.breakpoints.down("md")]: {
      padding: theme.spacing(3),
    },
    [theme.breakpoints.up("lg")]: {
      padding: theme.spacing(6),
    },
  },
  map: {
    width: "50%",
    [theme.breakpoints.up("sm")]: {
      // padding: `0 ${theme.spacing(3)}px`,
    },
  },
}));

export default function () {
  const classes = useStyles();

  // https://dev.to/paramharrison/background-image-with-gradient-overlay-in-css-4gcb
  return (
    <Box
      className={classes.root}
      style={{
        backgroundColor: "#f9f8f9",
      }}
      pt={18}
      pb={10}
    >
      <Container>
        <Box
          display="flex"
          style={
            {
              // backgroundColor: "#fff",
            }
          }
        >
          <Grid container spacing={0}>
            <Grid item xs={12} md={6}>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3439.7252775939933!2d-91.16079978487544!3d30.44388888173876!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8626a139922ec9a7%3A0x8295c9f2dfe66872!2sFabric%20Care%20Washateria!5e0!3m2!1sen!2sus!4v1592454662947!5m2!1sen!2sus"
                width="100%"
                height="100%"
                frameborder="0"
                style={{ border: 0 }}
                allowfullscreen=""
                aria-hidden="false"
                tabindex="0"
              ></iframe>
              {/*<iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d27517.364092442618!2d-91.158569!3d30.445441!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8626a139939bb46b%3A0xcfa5948eaaa8d6d2!2s2928+Government+St%2C+Baton+Rouge%2C+LA+70806!5e0!3m2!1sen!2sus!4v1503088801098"
                width="100%"
                height="100%"
                frameBorder="0"
                style={{ border: 0 }}
                title="Fabric Care Map"
                allowFullScreen
              ></iframe>*/}
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                flexGrow={1}
                className={classes.textContainer}
                style={{
                  backgroundColor: "#fff",
                }}
              >
                <Box pb={1}>
                  <Typography
                    variant="h6"
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    Store Location
                  </Typography>
                </Box>
                <Box pb={3}>
                  <Box pb={1}>
                    <Typography variant="body1">
                      <a
                        href="https://goo.gl/maps/5ZjNEA7mntxqwus46"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        2928 Government St
                        <br />
                        Baton Rouge, LA 70806
                      </a>
                    </Typography>
                  </Box>
                  <Typography variant="body1">
                    We're located in front of Baton Rouge High School.
                  </Typography>
                  <Typography variant="body1">
                    Our drive-through service is located at the back.
                  </Typography>
                </Box>
                <Box pb={1}>
                  <Typography
                    variant="h6"
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    Store Hours
                  </Typography>
                </Box>
                <Box pb={3}>
                  <Typography variant="body1">
                    Sun-Sat: 7am-10:30pm
                    <br />
                    Last wash at 9:30pm
                    <br />
                    Christmas Eve (12/24): 7am-5pm
                    <br />
                    Christmas Day (12/25): Closed
                  </Typography>
                </Box>
                <Box pb={1}>
                  <Typography
                    variant="h6"
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    Contact Number
                  </Typography>
                </Box>
                <Box>
                  <a href="tel:225-383-8729">225-383-8729</a>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}
